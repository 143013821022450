import { gql, TypedDocumentNode } from '@apollo/client'

import type { Customer, User } from './main-types'

export interface GET_USER_DATA {
  user: (User & { customer: Customer; isInternal: boolean })[]
}

export type UserCustomer = User & { customer: Customer }

export const GET_USER: TypedDocumentNode<{
  user: [UserCustomer?]
}> = gql`
  subscription getUser {
    user {
      id
      email
      role
      firstName: first_name
      lastName: last_name
      picture
      canResetCustomerData: can_reset_customer_data
      canDeleteProjects: can_delete_projects
      role
      isInternal: is_internal
      customer {
        id
        name
        updatedAt: updated_at
        createdAt: created_at
        features
        featuresByModel: features_by_model
        hasOptionRationale: has_option_rationale
        ssoConnectionName: sso_connection_name
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $picture: String, $firstName: String, $lastName: String) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { picture: $picture, first_name: $firstName, last_name: $lastName }
    ) {
      id
    }
  }
`
