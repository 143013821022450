/* eslint-disable max-len */
// @TODO - FILE DEPRECATED - SHOULD ME MOVE TO ./quality-metrics/*

import { get } from 'lodash'

import { SUGGEST_CORRECT, SUGGEST_DISTRACTOR } from '@author/constants/optionsStatus'

import { getFkGradeLevelScore, getFkReadEaseScore } from './quality-metrics/flesch-kincaid'

const SENTENCE_END_RE = /.*?(?:\.|!|\?|"|\u201d)(?:(?= [A-Z0-9])|$)/gm

export const getTextMCQ = (content) => {
  const passage = get(content, 'passage', '')
  const parts = content?.has_generated_passage && passage ? [passage] : []
  parts.push(content?.question)
  ;['answers', 'parts'].map((partsName) => {
    const partName = get(content, partsName, [])
    partName.map((part) => {
      const { status } = part
      if (status && ![SUGGEST_CORRECT, SUGGEST_DISTRACTOR].includes(status)) parts.push(part.value)
      return part
    })
    return partsName
  })
  return parts
}

export const getStoryItemText = (content) => {
  const parts: string[] = []
  content.blocks?.forEach(({ instances }) => {
    const { versions } = instances?.find((item) => item.active) || {}
    if (versions) {
      const newestVersion = versions[versions.length - 1]
      parts.push(newestVersion.paragraph)
    }
  })
  return parts
}

export const getText = (content) => {
  const parts = content?.blocks ? getStoryItemText(content) : getTextMCQ(content)
  const partsStripped = parts.map((part) => part?.trim())
  return partsStripped.join('\n').replace(/(\\\(.*?\\\))/gm, 'latex')
}

export const getTotalSentences = (content) => {
  const text = getText(content)
  return text.match(SENTENCE_END_RE)?.length || 0
}

export const getWords = (content) => {
  return getText(content)
    .replace(/[.,?!"]/g, '')
    .split(/[\s]+/)
}

export const getTotalWords = (content) => {
  return getWords(content).length
}

export const getTotalUniqueWords = (content) => {
  const uniqueWords = new Set(getWords(content))
  return uniqueWords.size
}

export const getTypeTokenRatio = (content) => {
  const totalWords = getTotalWords(content)
  const totalUniqueWords = getTotalUniqueWords(content)
  return totalWords ? totalUniqueWords / totalWords : 0
}

export const getFleschRe = (content) => {
  const text = getText(content)
  return getFkReadEaseScore(text)
}

export const getClIndex = (content) => {
  const totalLetters = Math.max(1, getWords(content).join('').length)
  const totalWords = Math.max(1, getTotalWords(content))
  const totalSentences = getTotalSentences(content)

  const L = 100 * (totalLetters / totalWords)
  const S = 100 * (totalSentences / totalWords)
  return 0.0588 * L - 0.296 * S - 15.8
}

export const getFleschKincaidGl = (content) => {
  const text = getText(content)
  return getFkGradeLevelScore(text)
}

export const QUALITY_METRICS = {
  'Word Count': getTotalWords,
  'Unique Word Count': getTotalUniqueWords,
  'Type-Token Ratio': getTypeTokenRatio,
  'Flesch Reading Ease': getFleschRe,
  'Coleman-Liau Index': getClIndex,
  'Flesch-Kincaid Grade Level': getFleschKincaidGl,
}

export const getQualityMetrics = async (content, aiModel) => {
  const itemQualityMetrics: string[] = []

  aiModel?.qualityMetrics.map((qualityMetric) => {
    const { name } = qualityMetric
    const qualityMetricFunc = QUALITY_METRICS[name]

    if (!qualityMetricFunc) {
      throw Error(`Quality metric ${name} is not defined`)
    }

    const itemQualityMetric = {
      ...qualityMetric,
      value: qualityMetricFunc(content),
    }
    itemQualityMetrics.push(itemQualityMetric)

    return qualityMetric
  })
  return { qualityMetrics: itemQualityMetrics }
}
