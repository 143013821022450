import get from 'lodash/get'

const UNITS = '_units'
export const INPUTS = '_inputs'
const CORPUS_UNITS = '_corpus_units'

interface Tree {
  [key: string]: any
}
export const isChild = (tree: Tree, nodeName: string | undefined, depth: number) => {
  if (!nodeName) {
    return false
  }
  if (depth === 0) {
    return Object.keys(tree).includes(nodeName)
  }

  let result = false

  Object.values(tree).forEach((subtree) => {
    if (isChild(subtree, nodeName, depth - 1)) {
      result = true
    }
  })

  return result
}

const isMetadataField = (name: string) => {
  return name.startsWith('_')
}

export const getNodesNamesFromTree = (tree: Tree, depth: number) => {
  if (!tree) return []
  if (depth === 0) {
    return Object.keys(tree).filter((key) => !isMetadataField(key))
  }

  const options: string[] = []

  const subtrees = Object.entries(tree).filter(([key]) => !isMetadataField(key))
  subtrees.forEach(([, subtree]) => options.push(...getNodesNamesFromTree(subtree, depth - 1)))
  return options
}

const filterTree = (tree: Tree, name: string, depth: number) => {
  const newTree = {}

  if (depth === 0) {
    const newSubtree = tree[name]
    return newSubtree ? { [name]: newSubtree } : undefined
  }

  Object.entries(tree).forEach(([subtreeName, subtree]) => {
    const newSubtree = filterTree(subtree, name, depth - 1)
    if (newSubtree) {
      newTree[subtreeName] = newSubtree
    }
  })

  return Object.keys(newTree).length !== 0 ? newTree : undefined
}

export const filter = (tree: Tree, names: (string | undefined)[]) => {
  let newTree = tree

  names.forEach((name, index) => {
    if (name) {
      newTree = filterTree(newTree, name, index) as Tree
    }
  })

  return newTree
}

const _getMetadata = (tree: Tree) => {
  const keys = Object.keys(tree)
  const metadataKeys = keys.filter(([key]) => isMetadataField(key))
  return metadataKeys.reduce(
    (result, key) => ({
      ...result,
      [key]: tree[key],
    }),
    {},
  )
}

export const getMetadata = (tree: Tree, depth: number): Tree => {
  if (depth === 0) {
    return _getMetadata(tree)
  }

  let options: Tree = {}
  Object.entries(tree).forEach(([name, subtree]) => {
    if (!isMetadataField(name)) {
      const metadata = getMetadata(subtree, depth - 1)
      if (depth === 1) {
        options[name] = metadata
      } else {
        options = { ...options, ...metadata }
      }
    }
  })
  return options
}

const DISABLED_TOOLTIP =
  'Option disabled. Select another option in the hierarchy to enable other options.'

export const getOptionsFromTree = ({
  flavors,
  depth,
  selectedFlavors,
}: {
  flavors: Tree
  depth: number
  selectedFlavors: (string | undefined)[]
}) => {
  const metadataByName = getMetadata(flavors, depth + 1)
  const allNodesNames = new Set(getNodesNamesFromTree(flavors, depth))
  const otherSelectedFlavors = selectedFlavors.map((s, index) => (index !== depth ? s : undefined))
  const activeTree = filter(flavors, otherSelectedFlavors)
  const activeNodesNames = new Set(getNodesNamesFromTree(activeTree, depth))
  const options: Tree[] = []

  allNodesNames.forEach((name: string) => {
    const disabled: boolean = !activeNodesNames.has(name)
    const option = {
      name,
      ...metadataByName[name],
      disabled,
      tooltip: disabled ? DISABLED_TOOLTIP : name,
    }
    options.push(option)
  })

  return options
}

const subIsTree = (subtree) => {
  return (
    typeof subtree === 'object' &&
    !Object.keys(subtree).includes(UNITS) &&
    !Object.keys(subtree).includes(CORPUS_UNITS)
  )
}

export const depthOf = (tree: Tree): number => {
  const treeWithoutMetadata: Tree = Object.keys(tree)
    .filter(([key]) => !isMetadataField(key))
    .reduce((result, key) => ({ ...result, [key]: tree[key] }), {})

  const subtree = Object.values(treeWithoutMetadata)[0]

  if (!subIsTree(subtree)) {
    return 1
  }
  return depthOf(subtree) + 1
}

export const getUniqueLeafs = (tree: Tree, name: string = '', result = new Set<string>()) => {
  const keys = Object.keys(tree).filter((key) => !isMetadataField(key))

  if (keys.length === 0) {
    result.add(name)
    return Array.from(result)
  }

  Object.entries(tree).forEach(([key, value]) => {
    if (key.startsWith('_')) {
      return
    }

    getUniqueLeafs(value, key, result)
  })

  return Array.from(result)
}

export const getAllLeafs = (
  flavors: Tree | null | undefined,
  selected: (string | undefined)[] | null,
) => {
  if (!flavors) return []
  return (
    selected?.reduce(
      (acc, name) => {
        if (name) {
          return acc[0] && acc.flatMap((node) => node[name] || [])
        }

        return acc.flatMap((node) =>
          Object.entries(node).reduce(
            (children, [key, value]) => (isMetadataField(key) ? children : children.concat(value)),
            [],
          ),
        )
      },
      [flavors],
    ) || []
  )
}

export const getMetadataFromSelectedFlavors = (
  tree: Tree | null | undefined,
  selectedFlavors: (string | undefined)[],
) => {
  if (!tree || selectedFlavors.some((s) => !s)) return null
  const cleanedFlavors = selectedFlavors.map((v) => (!v ? '' : v))
  const leaf = get(tree, cleanedFlavors)

  if (!leaf) {
    return undefined
  }

  return getMetadata(leaf, 0)
}

export const areInputsEnabled = (flavors: Tree, selectedFlavors: (string | undefined)[]) => {
  const metadata = getMetadataFromSelectedFlavors(flavors, selectedFlavors)
  const selectedFlavorsFiltered = (selectedFlavors || []).filter(Boolean)
  const showInputsMetadata = flavors && getMetadata(flavors, 0)?._show_inputs_if_selected

  let showInputs = false

  if (Array.isArray(showInputsMetadata)) {
    const matchingSelections = showInputsMetadata.filter(
      (show: boolean, index: number) => show && Boolean(selectedFlavors[index]),
    )

    showInputs = matchingSelections.length === showInputsMetadata.filter((show) => show).length
  }

  // This checks happens when not all submodels are selected and
  // last selected submodel metadata is checked for _inputs: false
  if (selectedFlavorsFiltered.length) {
    const cleanedFlavorsFiltered = selectedFlavorsFiltered.map((v) => (!v ? '' : v))
    const leaf = get(flavors, cleanedFlavorsFiltered)
    if (leaf) {
      const leafMetadata = getMetadata(leaf, 0)
      if (leafMetadata && Object.keys(leafMetadata).length) {
        if ('_inputs' in leafMetadata) {
          showInputs = leafMetadata[INPUTS] as boolean
        }
      }
    }
  }

  // This checks happens when all submodels are selected
  // It checks for last submodel for _inputs: false
  // Otherwise shows flexible inputs.
  if (metadata && Object.keys(metadata).length) {
    showInputs = true
    if ('_inputs' in metadata) {
      showInputs = metadata[INPUTS] as boolean
    }
  }

  return showInputs
}
