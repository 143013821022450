import { useMemo } from 'react'
import _ from 'lodash'

import { isCommentsMatch } from '@containers/comments/comments-utils'
import { getClient } from '@core/api/api-state'
import { PASSAGE_BUILDER, STORY } from '@core/constants/content-type'
import { useContentVersionState, type Items } from '@core/content-version'
import { notifyPossibleKeys } from '@core/content-version/content-version-actions'
import { getUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { ItemStatus, ItemStatusMap } from '@core/types'
import { getQualityMetrics } from '@core/utils/qualityMetrics'
import { getExtractedContent } from '@core/utils/text'
import {
  useItemWatchStatus,
  type InitializeOptions,
} from '@core/watch-item-status/watch-item-status-state'
import { ErrorMessage } from '@message/error'

import * as queries from './author-queries'
import type { Item2, RawItem } from './author-types'
import { useBatches } from './batches/batches-state'

// ---------------------------------------------------------------------------

/**
 * Check if there are unsaved changes in the item set
 * @returns true if there are unsaved changes
 */
export const checkUnsavedChanges = (items: Items): boolean => {
  const { root } = items

  const draft = root.currentContent
  const saved = root.savedContent

  if (!saved) return true

  if (!isCommentsMatch(draft.comments, saved.comments)) {
    return true
  }

  const commonFields = [
    'submodels',
    'inputs',
    'comments',
    'content',
    'externalMetadata',
    'itemBias',
  ]

  for (const field of commonFields) {
    if (!_.isEqual(draft[field], saved[field])) {
      return true
    }
  }

  return false
}

const initContentManager = (rawItem: RawItem) => {
  const items = {
    root: {
      ...rawItem,
      type: rawItem.contentType,
      status: rawItem.status,
      currentContent: rawItem.currentContentVersion[0] || null,
      savedContent: rawItem.savedContentVersion[0] || null,
      originalContent: rawItem.originalContentVersion[0] || null,
    },
  }

  return useContentVersionState.getState().initManager(rawItem.id, items, {
    async buildMetrics(itemMap) {
      if (!itemMap.root?.currentContent) return []

      const { qualityMetrics } = await getQualityMetrics(
        itemMap.root.currentContent.content,
        rawItem.aiModel,
      )

      return [
        {
          id: 'root',
          path: 'qualityMetrics',
          value: qualityMetrics,
        },
      ]
    },

    validate: () => {
      return {
        errors: {},
        totalErrors: 0,
        totalItemsWithError: 0,
      }
    },

    checkUnsavedChanges,
  })
}

export const fetchItemContent = async (itemId: string) => {
  try {
    const client = getClient()
    const userAccess = getUserRole()

    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: queries.GET_ITEM_CONTENT_VERSION,
      variables: { itemId },
      context: { role: userAccess.role },
    })

    const { currentContentVersion, originalContentVersion, ...item } = data.item
    const currentContent = currentContentVersion[0] || null
    const originalContent = originalContentVersion[0] || null
    const hasContent = Boolean(currentContent?.content)

    useContentVersionState
      .getState()
      .setItem(
        itemId,
        'root',
        (prev) => ({ ...prev, currentContent, status: item.status, originalContent }),
        item.status === ItemStatusMap.DONE && hasContent,
      )

    return { ...item, currentContent }
  } catch (error) {
    addSnack({
      message: `Error while fetching the Item. ${error.message}`,
      severity: 'error',
    })

    throw error
  }
}

const showAiResponseError = (aiResponses: Item2['aiResponses']) => {
  const errorMessage = ErrorMessage.getAIResponseErrorMessage(
    aiResponses.length === 1 ? aiResponses[0].error : null,
  )

  addSnack({ message: errorMessage, severity: 'error' })
}

const updateItemStatus = (
  itemId: string,
  status: ItemStatus,
  updatedAt = new Date().toISOString(),
) => {
  useContentVersionState
    .getState()
    .setItem(itemId, 'root', (prev) => ({ ...prev, status, updatedAt }), false)
}

const watchStatusOptions: InitializeOptions = {
  refetchItem(item) {
    return fetchItemContent(item.id)
  },

  async onDone(item, prev) {
    const itemUpdated = await fetchItemContent(item.id)

    if (prev.status === ItemStatusMap.POSSIBLE_KEYS) {
      notifyPossibleKeys(itemUpdated.currentContent.content.answers)
    }

    return { status: itemUpdated.status }
  },
  async onFail(item) {
    const itemUpdated = await fetchItemContent(item.id)

    showAiResponseError(itemUpdated.aiResponses)

    return { status: itemUpdated.status }
  },
  onChange(item) {
    updateItemStatus(item.id, item.status, item.updatedAt)
  },
  onTimeout(item, newStatus) {
    updateItemStatus(item.id, newStatus)
  },
}

export const fetchItem = async (itemId: string) => {
  try {
    const client = getClient()
    const userAccess = getUserRole()

    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: queries.GET_ITEM,
      variables: { itemId, userId: userAccess.user.id },
      context: { role: userAccess.role },
    })

    if (!data?.item) {
      addSnack({ message: 'Item not found', severity: 'error' })
      return { notFound: true }
    }

    if ([PASSAGE_BUILDER, STORY].includes(data.item?.contentType || '')) {
      return { isStoryModal: true }
    }

    // only the job owner can see the batches, so redirect only if is the owner
    const isJobOwner = data.item && data.item.job?.ownerId === userAccess.user.id

    // automatically select the batch tab if the item is part of a batch
    if (isJobOwner && data.item.job.itemsRequired > 1) {
      useBatches.setState({
        selectedBatchId: data.item.job.id,
      })
    }

    // Reset batch if item is not part of batch
    const { selectedBatchId } = useBatches.getState()
    if (selectedBatchId !== data.item.job.id) {
      useBatches.setState({ selectedBatchId: null })
    }

    useItemWatchStatus
      .getState()
      .init(
        data.item.id,
        [{ id: data.item.id, updatedAt: data.item.updatedAt, status: data.item.status }],
        watchStatusOptions,
      )

    initContentManager(data.item)

    const hasContent = Boolean(data.item.currentContentVersion[0]?.content)

    if (!hasContent && data.item.status === ItemStatusMap.FAILED) {
      showAiResponseError(data.item.aiResponses)
    }

    return { item: data.item }
  } catch (error) {
    addSnack({
      message: `Error while fetching the Item. ${error.message}`,
      severity: 'error',
    })

    throw error
  }
}

// ---------------------------------------------------------------------------

type UseItem = <T>(rootId: string, selector: (contentVersion: Item2) => T) => T

export const useItem: UseItem = (rootId, selector) => {
  return useContentVersionState((state) => {
    const instance = state.instances[rootId]

    if (!instance) {
      throw new Error(`useItem: instance for ${rootId} not found`)
    }

    const item = instance.items.root

    return selector(item as unknown as Item2)
  })
}

export const useReferencesTab = (itemId?: string) => {
  return useContentVersionState((state) => {
    if (!itemId) return { enabled: false, aiModelId: '' }

    const item = state.instances[itemId]?.items.root as unknown as Item2 | undefined

    if (!item?.aiModel) return { enabled: false, aiModelId: '' }

    return { enabled: item.aiModel.itemRationaleReferences, aiModelId: item.aiModel.id }
  })
}

export const buildReferencesQuery = (itemId: string, selectedOption: string): string => {
  const item = useContentVersionState.getState().instances[itemId]?.items.root as unknown as Item2

  if (!item || !item.currentContent) {
    console.warn('buildReferencesQuery: item not found', { itemId })
    return ''
  }

  const { question } = item.currentContent.content
  const resourceInputs = item.aiModel.itemRationaleInputs || []

  const resourceInputContents = resourceInputs
    .map((path) => getExtractedContent(item.currentContent.content, path.replace('content.', '')))
    .filter((value) => Boolean(value.trim()))
    .join(' ')
    .trim()

  return `${`${resourceInputContents || question} `}${selectedOption}`
}

const inProgressStatuses: ItemStatus[] = [
  ItemStatusMap.IN_PROGRESS,
  ItemStatusMap.NOT_STARTED,
  ItemStatusMap.POSSIBLE_KEYS,
  ItemStatusMap.RATIONALES,
]

export const checkIsInProgress = (status: ItemStatus) => {
  return inProgressStatuses.includes(status)
}

export const useLoadings = (itemId?: string) => {
  const [status, hasContent] = useContentVersionState((state) => {
    if (!itemId) {
      return ['' as ItemStatus, false]
    }

    const instance = state.instances[itemId]

    if (!instance) {
      return ['' as ItemStatus, false]
    }

    const item = instance.items.root as unknown as Item2

    const { content } = item.currentContent || {}

    return [
      item.status,
      Boolean(content?.question || content?.answers || content?.parts || content?.options),
    ]
  })

  return useMemo(() => {
    const inProgress = !status || checkIsInProgress(status)

    return {
      itemId,
      fetchingItem: !status,
      any: status !== ItemStatusMap.DONE && status !== ItemStatusMap.FAILED,
      generate: inProgress && !hasContent,
      regenerate: status === ItemStatusMap.NOT_STARTED || status === ItemStatusMap.IN_PROGRESS,
      possibleKeys: status === ItemStatusMap.POSSIBLE_KEYS,
      rationales: status === ItemStatusMap.RATIONALES,
      pretestBias: status === ItemStatusMap.PRETEST_BIAS,
      pretestKey: status === ItemStatusMap.PRETEST_KEY,
      generateMath: status === ItemStatusMap.GENERATE_MATH,
    }
  }, [status, hasContent])
}
