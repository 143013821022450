import React from 'react'
import { Box, Tooltip, TooltipProps } from '@mui/material'

export type Props = TooltipProps & {
  withMargin?: boolean
  disabled?: boolean
  // if it should put a div around the children to use as ref
  wrap?: boolean
  maxWidth?: number
}

const FTGTooltip = ({ withMargin, children, disabled, wrap, maxWidth, ...props }: Props) => {
  if (disabled) {
    return children
  }

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '0.85rem',
            overflowWrap: 'break-word',
            mr: withMargin ? 1 : 0,
            maxWidth: maxWidth || 300,
          },
        },
      }}
      {...props}
      role="tooltip"
    >
      {wrap ? <Box width="100%">{children}</Box> : children}
    </Tooltip>
  )
}

export default FTGTooltip
