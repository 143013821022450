function getAvgWordsPerSentence(text: string): number {
  let totalWords = 0

  // Split the text into sentences
  const sentences = text.match(/\b[^.!?]+[.!?]*/g) || []
  for (const sentence of sentences) {
    const words = sentence.split(/\s+/)
    totalWords += words.length
  }

  return sentences.length > 0 ? totalWords / sentences.length : 0
}

function getAvgSyllablesPerWord(text: string): number {
  // Remove all non-alphanumeric characters
  const justWords = text.replace(/[^a-zA-Z0-9\s]/g, '')

  // Calculate the average number of syllables per word
  const wordList = justWords.split(/\s+/)
  let totalSyllables = 0

  for (const word of wordList) {
    let syllables = 0

    for (const vowel of ['a', 'e', 'i', 'o', 'u']) {
      syllables += (word.match(new RegExp(vowel, 'g')) || []).length
    }

    for (const ending of ['es', 'ed', 'e']) {
      if (word.endsWith(ending)) {
        syllables -= 1
      }
    }

    if (word.endsWith('le')) {
      syllables += 1
    }

    if (syllables === 0) {
      syllables = 1
    }

    totalSyllables += syllables
  }

  return wordList.length > 0 ? totalSyllables / wordList.length : 0
}

export function getFkGradeLevelScore(text: string): number {
  return 0.39 * getAvgWordsPerSentence(text) + 11.8 * getAvgSyllablesPerWord(text) - 15.59
}

export function getFkReadEaseScore(text: string): number {
  return Math.max(
    206.835 - 1.015 * getAvgWordsPerSentence(text) - 84.6 * getAvgSyllablesPerWord(text),
    0
  )
}
