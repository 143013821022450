import React, { memo } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  StyleProps,
  Typography,
} from '@mui/material'
import { useLocation } from 'react-router-dom'

import { useDownloadItems } from '@containers/download-items-menu/download-items-state'
import { useApi, useSubscription } from '@core/api'
import FTGTooltip from '@core/components/FTGTooltip'
import { useIsProBuilderIntegrationEnabled } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { downloadFile, getFilenameFromHeaders } from '@core/utils/download'

import { useExportJobs, type ExportJob } from './export-items-state'
import * as queries from './export-queries'
import { getErrorMessage } from './export-utils'
import StatusIcon from './status-icon'

type DownloadItemProps = {
  isLastItem: boolean
  order: number
  job: ExportJob
}

const styles = {
  menu: {
    display: 'flex',
    '& .Mui-disabled': { opacity: 1 },
    py: 1.5,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    width: 350,
    position: 'fixed',
    bottom: 20,
    right: 20,
    top: 'unset',
    left: 'unset',
    zIndex: 99999,
  },

  clearContainer: {
    bgcolor: 'background.blueGrey',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    '&.Mui-disabled': { opacity: 1 },
  },
  readyText: {
    color: 'secondary.main',
    '&:hover, &:visited': {
      textDecoration: 'underline',
    },
  },
} satisfies StyleProps

const ZIP_FORMAT = 'zip/'

const exportLabel = {
  'zip/csv': 'CSV',
  'zip/txt': 'Text',
  'zip/qtiv2p1': 'QTI',
  'zip/comptia': 'CompTia',
  'zip/surpass': 'Surpass',
}

const DownloadItem = ({ order, job, isLastItem }: DownloadItemProps) => {
  const isLoading = job.status === 'pending' || job.status === 'in_progress'
  const isFailed = job.status === 'failed'
  const isWaitingUserInput = job.status === 'waiting_user_input'
  const isCompletedWithIssues = job.status === 'completed_with_issues'

  const api = useApi()

  const [updateExportJobDialog] = useExportJobs((state) => [state.updateDialog])

  const onClick = async () => {
    if (job.exportType === 'ProBuilder') {
      return updateExportJobDialog({ selectedJobId: job.id, tab: 0, open: true })
    }

    try {
      const { data, response } = await api.downloadExportedFile({
        path: { export_id: job.id },
      })
      const url = URL.createObjectURL(data as unknown as Blob)
      const filename = getFilenameFromHeaders(response.headers) || job.fileName

      if (url === 'testURL') return

      downloadFile(url, filename, { openInNewTab: true })
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error('error while downloading items', error)

      addSnack({
        message: `Error downloading items: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }

  let text = `${order}. ${job.label || job.fileName}`
  let secondaryText = isFailed ? getErrorMessage(job.errorDetails).message : ''
  let tooltip = `Click to download ${job.label || job.fileName}`

  if (job.exportType.startsWith(ZIP_FORMAT)) {
    text = `${order}. ${job.fileName}.zip (${exportLabel[job.exportType]})`
  }

  let icon = <StatusIcon status={job.status} />

  if (job.exportType === 'ProBuilder') {
    text = `${order}. ProBuilder: ${job.fileName}`
    tooltip = `Click to see export details of ${job.fileName}`

    if (isLoading) {
      const totalDone = job.totalCompleted + job.totalFailed
      icon = (
        <CircularProgress
          color="secondary"
          size={20}
          aria-label="loading download"
          variant={totalDone > 0 ? 'determinate' : 'indeterminate'}
          value={(totalDone / job.totalItems) * 100}
        />
      )
    }

    if (isCompletedWithIssues) {
      secondaryText = 'Completed with issues'
    }

    if (isWaitingUserInput) {
      secondaryText = 'Waiting for user input'
      tooltip = 'The export job is waiting for user input. Click to see export details.'
    }
  }

  return (
    <MenuItem
      autoFocus={false}
      disabled={job.exportType === 'ProBuilder' ? false : isLoading || isFailed}
      onClick={onClick}
      sx={styles.menu}
      divider={!isLastItem}
      disableGutters
      aria-label={`${order} export job status ${job.status}`}
    >
      <FTGTooltip title={tooltip} placement="left" withMargin>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', pl: 2 }}>
          <ListItemText
            id="test"
            sx={isLoading ? { opacity: 0.38 } : styles.readyText}
            primaryTypographyProps={{ noWrap: true }}
            secondaryTypographyProps={{
              color: 'error',
              noWrap: true,
            }}
            primary={text}
            secondary={secondaryText}
          />

          <ListItemIcon sx={{ justifyContent: 'flex-start' }}>{icon}</ListItemIcon>
        </Box>
      </FTGTooltip>
    </MenuItem>
  )
}

const selectStats = (jobs: ExportJob[] | null, probuilderEnabled?: boolean) => {
  let label = 'Downloads'

  if (probuilderEnabled) {
    label = 'Exports'
  }

  if (!jobs) {
    return { ids: [], isLoading: false, label }
  }

  let isLoading = false
  const ids: string[] = []

  for (const job of jobs) {
    if (job.status === 'in_progress' || job.status === 'pending') {
      isLoading = true
    }

    ids.push(job.id)
  }

  if (probuilderEnabled) {
    label += ` (${ids.length})`
  } else {
    label += ` ${ids.length}`
  }

  return { ids, isLoading, label }
}

const DownloadMenu = () => {
  const downloadMenu = useDownloadItems()
  const [addExportJob, exportJobs] = useExportJobs((state) => [state.add, state.exportStatus.jobs])
  const isProBuilderEnabled = useIsProBuilderIntegrationEnabled()
  const location = useLocation()

  const handleClose = async () => {
    downloadMenu.onCancel()
    useExportJobs.setState({
      exportStatus: { jobs: null },
    })
  }

  const stats = React.useMemo(() => selectStats(exportJobs, isProBuilderEnabled), [exportJobs])

  useSubscription(queries.EXPORT_JOB_STATUS, {
    variables: { ids: stats.ids },
    skip: !stats.ids.length,
    onData: ({ data }) => {
      data?.data?.exportJobs?.forEach(addExportJob)
    },
  })

  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  const hideExportMenu = !location.pathname.startsWith('/deliver')

  if (hideExportMenu || !exportJobs || exportJobs.length === 0) {
    return null
  }

  return (
    <Paper sx={styles.paper} elevation={3}>
      <MenuList autoFocus={false} sx={{ p: 0 }}>
        <ListItem sx={styles.menu} divider>
          <Typography id="grab-dialog" sx={{ flex: 1 }} variant="h6">
            {stats.label}
            {stats.isLoading ? '...' : ''}
          </Typography>
          <IconButton onClick={handleClick}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </ListItem>

        <Collapse in={open} timeout="auto">
          <ListItem dense sx={styles.clearContainer}>
            <Typography variant="body2" sx={{ opacity: 0.38 }}>
              {stats.isLoading ? 'Processing...' : 'Done'}
            </Typography>
            <Button size="small" variant="text" color="secondary" onClick={handleClose}>
              Clear
            </Button>
          </ListItem>
          <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
            {exportJobs.map((job, index) => {
              return (
                <DownloadItem
                  key={job.id}
                  job={job}
                  order={index + 1}
                  isLastItem={index === exportJobs.length - 1}
                />
              )
            })}
          </Box>
        </Collapse>
      </MenuList>
    </Paper>
  )
}

export default memo(DownloadMenu)
